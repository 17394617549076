<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="show" persistent width="800">
        <!--<template v-slot:activator="{ props }">
            <v-btn color="primary" v-bind="props"> Open Dialog </v-btn>
          </template>-->
        <v-card>
          <v-card-title>
            <span class="text-h5">Datos Usuario Editar</span>
          </v-card-title>
          <v-card-text>
            <v-form @submit.prevent="validate()" v-model="isFormValid">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      label="Nombre (s)"
                      v-model="formData.nombre"
                      :rules="nombreR"
                      variant="solo"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      label="Apellido Paterno"
                      v-model="formData.apellido"
                      :rules="apellidosR"
                      variant="solo"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      label="Apellido Materno"
                      v-model="formData.apellido_mat"
                      :rules="apellidosR"
                      variant="solo"
                    ></v-text-field>
                  </v-col>

                  <!--<v-col cols="12" sm="6" >
                    <v-select
                      label="Selecciona un Rol"
                      :items="roles"
                      variant="solo"
                      v-model="formData.rol"
                      :rules="rolR"
                    ></v-select>
                  </v-col>-->

                  <v-col cols="12" sm="6">
                    <v-text-field
                      type="number"
                      label="Teléfono"
                      v-model="formData.telefono"
                      :rules="telefonoR"
                      variant="solo"
                    ></v-text-field>
                  </v-col>

                  <!--<v-col cols="12" sm="6">
                    <v-text-field
                      type="email"
                      label="Email"
                      v-model="formData.correo"
                      :rules="emailR"
                      variant="solo"
                    ></v-text-field>
                  </v-col>-->

                  <!--<v-col cols="12" sm="6">
                    <v-text-field
                      label="Contraseña"
                      v-model="formData.password"
                      :rules="passwordR"
                      variant="solo"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Confirmar Contraseña"
                      v-model="formData.confirm"
                      :rules="[(formData.confirm === formData.password) || 'Las contraseñas no coinciden']"
                      variant="solo"
                    ></v-text-field>
                  </v-col>-->
                </v-row>
              </v-container>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="btn me-4 bg-green"
                  text="Editar"
                  type="submit"
                ></v-btn>
                <v-btn
                  class="btn ms-auto bg-red"
                  text="Cancelar"
                  @click="close()"
                ></v-btn>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script setup>
import { computed, reactive, onUpdated, ref } from "vue";

const isFormValid = ref(false);

const formData = reactive({
  id: 0,
  nombre: "",
  apellido: "",
  apellido_mat:"",
  telefono: "",
  rol: "",
});

//REGLAS

const nombreR = [
  (value) => {
    if (value) return true;

    return "Campo Requerido";
  },
];

const apellidosR = [
  (value) => {
    if (value) return true;

    return "Campo Requerido";
  },
];

/*const rolR = [
  (value) => {
    if (value) return true;

    return "Campo Requerido";
  },
];*/

const telefonoR = [
  (value) => {
    if (value) return true;

    return "Campo Requerido";
  },
  (value) => {
    if (value?.length == 10) return true;

    return "Solo 10 dígitos";
  },
];

/*const emailR = [
  (value) => {
    if (value) return true;

    return "Campo Requerido.";
  },
  (value) => {
    if (/.+@.+\..+/.test(value)) return true;

    return "Ingresa un correo valido.";
  },
];*/

/*const passwordR = [
  (value) => {
    if (value) return true;
    return "Campo Requerido.";
  },
  (value) => {
    if (value && value.toLowerCase() !== value) return true;
    return "Al menos una letra mayúscula";
  },
  (value) => {
    if (/\d/.test(value)) return true;
    return "Al menos un  número";
  },
  (value) => {
    if (/[^A-Za-z0-9]/.test(value)) return true;
    return "Al menos un simbolo";
  },
  (value) => {
    if (value && value.length >= 8) return true;
    return "Mínimo 8 carácteres";
  },
];*/

//const roles = ["Super Admin", "Administrativo"];

const show = computed({
  // getter
  get() {
    return props.banEdit;
  },
  // setter
  set() {
    // Note: we are using destructuring assignment syntax here.
    //handleReset();
    //emit("changeDialog")
  },
});

const validate = () => {
  //console.log("Bandera Form: " + isFormValid.value);

  if (isFormValid.value) {
    emit("edit",formData.id, formData);
    emit("changeEdit");
  }
};

const close = () => {
  //handleReset();
  emit("changeEdit");
};

/* EMITS */
const emit = defineEmits(["edit", "changeEdit"]);

/* PROPS */
const props = defineProps(["banEdit", "dataEdit"]);

onUpdated(() => {
  formData.id = props.dataEdit.id;
  formData.nombre = props.dataEdit.nombre;
  formData.apellido = props.dataEdit.apellido;
  formData.apellido_mat = props.dataEdit.apellido_mat;
  formData.correo = props.dataEdit.correo;
  formData.rol = props.dataEdit.rol;
  formData.telefono = props.dataEdit.telefono;
});
</script>

<style scoped></style>
