import axios from "axios";

class AuthService {
  url = "https://apirpm.solopruebas.nl";
  headers = {
    /*"Access-Control-Allow-Headers":
        "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
      "Access-Control-Allow-Origin": "*",*/
    "Content-Type": "application/json", //"Content-Type": "application/json",
  };

  async signin(datos) {
    const res = axios({
      url: this.url + "/api/Admins/login",
      method: "POST",
      headers: this.headers,
      data: JSON.stringify(datos),
    });

    return res;
  }

  async updateProfile(datos) {
    const res = axios({
      url: this.url + "/api/Admins/updateProfile",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      data: JSON.stringify(datos),
    });

    return res;
  }

  //falta end point
  async forgotPwd(datos) {
    const res = axios({
      url: this.url + "/api/User/ForgotPwd",
      method: "POST",
      headers: this.headers,
      data: JSON.stringify(datos),
    });

    return res;
  }

  async changePwd(datos) {
    const res = axios({
      url: this.url + "/api/Admins/CambioPwd",
      method: "POST",
      headers:{
        "Content-Type": "application/json", 
        'Authorization': 'Bearer ' + localStorage.getItem('jwt')
      },
      data: JSON.stringify(datos),
    });

    return res;
  }
}

export default AuthService;
