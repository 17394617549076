<script setup>
import { onBeforeMount, onMounted, onBeforeUnmount, ref } from "vue";
import { useStore } from "vuex";

import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
//import ProfileCard from "./components/ProfileCard.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";

import AuthService from "../services/AuthService";
import Swal from "sweetalert2";

const service = new AuthService();

const body = document.getElementsByTagName("body")[0];

const store = useStore();

let nombre = ref("");
let apellido = ref("");
let apellido_mat = ref("");
let banco = ref("");
let cuenta = ref("");
let clabe = ref("");
let telefono = ref("");
let direccion = ref("");
let ciudad = ref("");
let pais = ref("");
let estado = ref("");
let cp = ref("");
let municipio = ref("");

let pass = ref("");
let newPass = ref("");
let confirm = ref("");
const form = ref(null);

const passwordR = [
  (value) => {
    if (value) return true;
    return "Campo Requerido.";
  },
  (value) => {
    if (value && value.toLowerCase() !== value) return true;
    return "Al menos una letra mayúscula";
  },
  (value) => {
    if (value && value.toUpperCase() !== value) return true;
    return "Al menos una letra minúscula";
  },
  (value) => {
    if (/\d/.test(value)) return true;
    return "Al menos un  número";
  },
  (value) => {
    if (/[^A-Za-z0-9]/.test(value)) return true;
    return "Al menos un simbolo";
  },
  (value) => {
    if (value && value.length >= 8) return true;
    return "Mínimo 8 carácteres";
  },
];

const updateProfile = () => {
  let datos = {
    nombre: nombre.value,
    apellido: apellido.value,
    apellido_mat: apellido_mat.value,
    banco: banco.value,
    cuenta: cuenta.value,
    clabe: clabe.value,
    telefono: telefono.value,
    direccion: direccion.value,
    ciudad: ciudad.value,
    pais: pais.value,
    estado: estado.value,
    cp: cp.value,
    municipio: municipio.value,
  };
  service
    .updateProfile(datos)
    .then(function (response) {
      console.log(response);

      if (response.data.success) {
        Swal.fire({
          position: "top-end",
          title: "DATOS ACTUALIZADOS!",
          text: response.data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });

        localStorage.setItem("nombre", nombre.value);
        localStorage.setItem("apellido", apellido.value);
        localStorage.setItem("apellido_mat", apellido_mat.value);
        localStorage.setItem("banco", banco.value);
        localStorage.setItem("cuenta", cuenta.value);
        localStorage.setItem("clabe", clabe.value);
        localStorage.setItem("telefono", telefono.value);
        localStorage.setItem("direccion", direccion.value);
        localStorage.setItem("ciudad", ciudad.value);
        localStorage.setItem("pais", pais.value);
        localStorage.setItem("estado", estado.value);
        localStorage.setItem("cp", cp.value);
        localStorage.setItem("municipio", municipio.value);
      } else {
        Swal.fire({
          position: "top-end",
          title: "ERROR!",
          text: response.data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    })
    .catch(function (error) {
      console.log(error);
    })
    .then(function () {});
};

const cambiarPass = () => {
  let datos = {
    pwd: newPass.value,
    prevPwd: pass.value,
  };
  service
    .changePwd(datos)
    .then(function (response) {
      //console.log(response);
      if (response.data.success) {
        form.value.reset();
        Swal.fire({
          position: "top-end",
          title: "CONTRASEÑA ACTUALIZADA!",
          text: response.data.message,
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          position: "top-end",
          title: "ERROR!",
          text: response.data.message,
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    })
    .catch(function (error) {
      console.log(error);
      Swal.fire({
        position: "top-end",
        title: "ERROR!",
        text: error.response.data.message,
        icon: "error",
        showConfirmButton: false,
        timer: 2000,
      });
    })
    .then(function () {});
};

onMounted(() => {
  store.state.isAbsolute = true;
  setNavPills();
  setTooltip();
});
onBeforeMount(() => {
  store.state.imageLayout = "profile-overview";
  store.state.showNavbar = false;
  store.state.showFooter = true;
  store.state.hideConfigButton = true;
  body.classList.add("profile-overview");

  //Load Session Data
  console.log("ID: " + localStorage.getItem("id"));
  nombre.value = localStorage.getItem("nombre");
  apellido.value = localStorage.getItem("apellido");
  apellido_mat.value = localStorage.getItem("apellido_mat");
  banco.value = localStorage.getItem("banco");
  cuenta.value = localStorage.getItem("cuenta");
  clabe.value = localStorage.getItem("clabe");
  telefono.value = localStorage.getItem("telefono");
  direccion.value = localStorage.getItem("direccion");
  ciudad.value = localStorage.getItem("ciudad");
  pais.value = localStorage.getItem("pais");
  estado.value = localStorage.getItem("estado");
  cp.value = localStorage.getItem("cp");
  municipio.value = localStorage.getItem("municipio");
});
onBeforeUnmount(() => {
  store.state.isAbsolute = false;
  store.state.imageLayout = "default";
  store.state.showNavbar = true;
  store.state.showFooter = true;
  store.state.hideConfigButton = false;
  body.classList.remove("profile-overview");
});
</script>

<template>
  <main>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center">
                <p class="mb-0">Editar Perfil</p>
                <argon-button
                  color="primary"
                  size="sm"
                  class="ms-auto"
                  @click="updateProfile()"
                  >Actualizar</argon-button
                >
              </div>
            </div>
            <div class="card-body">
              <p class="text-uppercase text-sm">Información Usuario</p>
              <div class="row">
                <div class="col-md-4">
                  <label for="example-text-input" class="form-control-label"
                    >Nombre</label
                  >
                  <argon-input type="text" v-model="nombre" />
                </div>
                <div class="col-md-4">
                  <label for="example-text-input" class="form-control-label"
                    >Apellido Materno</label
                  >
                  <argon-input type="text" v-model="apellido_mat" />
                </div>
                <div class="col-md-4">
                  <label for="example-text-input" class="form-control-label"
                    >Apellido Paterno</label
                  >
                  <input class="form-control" type="text" v-model="apellido" />
                </div>
                <div class="col-md-6">
                  <label for="example-text-input" class="form-control-label"
                    >Teléfono</label
                  >
                  <argon-input type="text" v-model="telefono" />
                </div>
              </div>

              <!--<hr class="horizontal dark" />
              <p class="text-uppercase text-sm">Información de Contacto</p>
              <div class="row">
                <div class="col-md-12">
                  <label for="example-text-input" class="form-control-label"
                    >Dirección</label
                  >
                  <argon-input type="text" v-model="direccion" />
                </div>
                <div class="col-md-4">
                  <label for="example-text-input" class="form-control-label"
                    >Ciudad</label
                  >
                  <argon-input type="text" v-model="ciudad" value="New York" />
                </div>
                <div class="col-md-4">
                  <label for="example-text-input" class="form-control-label"
                    >País</label
                  >
                  <argon-input
                    type="text"
                    v-model="pais"
                    value="United States"
                  />
                </div>
                <div class="col-md-4">
                  <label for="example-text-input" class="form-control-label"
                    >Estado</label
                  >
                  <argon-input
                    type="text"
                    v-model="estado"
                    value="United States"
                  />
                </div>
                <div class="col-md-4">
                  <label for="example-text-input" class="form-control-label"
                    >Código Postal</label
                  >
                  <argon-input type="text" v-model="cp" value="437300" />
                </div>
                <div class="col-md-4">
                  <label for="example-text-input" class="form-control-label"
                    >Municipio</label
                  >
                  <argon-input type="text" v-model="municipio" value="437300" />
                </div>
              </div>-->
            </div>
          </div>
        </div>
        <hr />

        <div class="col-md-12">
          <div class="card">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center">
                <p class="mb-0">Actualizar Contraseña</p>
                <argon-button
                  color="primary"
                  size="sm"
                  class="ms-auto"
                  @click="cambiarPass()"
                  >Actualizar</argon-button
                >
              </div>
            </div>
            <div class="card-body">
              <p class="text-uppercase text-sm">Información Contraseñas</p>
              <div class="row">
                <v-form
                  role="form"
                  v-model="isFormValid"
                  ref="form"
                  class="row"
                >
                  <div class="col-md-4">
                    <v-text-field
                      label="Contraseña"
                      variant="solo"
                      v-model="pass"
                    ></v-text-field>
                  </div>
                  <div class="col-md-4">
                    <v-text-field
                      label="Nueva Contraseña"
                      variant="solo"
                      v-model="newPass"
                      :rules="passwordR"
                    ></v-text-field>
                  </div>
                  <div class="col-md-4">
                    <v-text-field
                      label="Confirmar Contraseña"
                      variant="solo"
                      v-model="confirm"
                      :rules="[
                        confirm === newPass || 'Las contraseñas no coinciden',
                      ]"
                    ></v-text-field>
                  </div>
                </v-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
