import { createRouter, createWebHistory } from "vue-router";
//import Dashboard from "../views/Dashboard.vue";
/*import Tables from "../views/Tables.vue";
import Billing from "../views/Billing.vue";
import VirtualReality from "../views/VirtualReality.vue";
import RTL from "../views/Rtl.vue";
import Signin from "../views/Signin.vue";*/

import Signup from "../views/Signup.vue";
import ForgotPassword from "../views/ForgotPassword.vue";

import DashboardView from "../views/DashboardView.vue";
import SolicitudesView from "../views/SolicitudesView.vue";
import ReportesView from "../views/ReportesView.vue";
import UsuariosView from "../views/UsuariosView.vue";
import AdministradoresView from "../views/AdministradoresView.vue";
import CursosViewAdmin from "../views/CursosView.vue";
import LibrosViewAdmin from "../views/LibrosView.vue";
import NoticiasViewAdmin from "../views/NoticiasView.vue";
import Profile from "../views/Profile.vue";
import changePassword from "../views/changePassView.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/signup",
  },
  /*{
    path: "/tables",
    name: "Tables",
    component: Tables,
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
  },
  {
    path: "/rtl-page",
    name: "RTL",
    component: RTL,
  },
  
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },*/

  /* RUTAS LOGIN */
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
    meta: {
      requireAuth: false,
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      requireAuth: false,
    },
  },
  /* RUTAS ADMIN */
  {
    path: "/dashboard",
    name: "Dashboard",
    component: DashboardView,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/solicitudes",
    name: "solicitudes",
    component: SolicitudesView,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/reportes",
    name: "Reportes",
    component: ReportesView,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/administrar/usuarios",
    name: "Administrar Usuarios",
    component: UsuariosView,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/administrar/administradores",
    name: "Usuarios Administradores",
    component: AdministradoresView,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/administrar/cursos",
    name: "Administrar Cursos",
    component: CursosViewAdmin,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/administrar/libros",
    name: "Administrar Libros",
    component: LibrosViewAdmin,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/administrar/noticias",
    name: "Administrar Noticias",
    component: NoticiasViewAdmin,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/changePassword",
    name: "Cambiar Contraseña",
    component: changePassword,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      requireAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  //
  /*const store = new useStore();
  const auth = store.state.session;
  const needAuth = to.meta.requireAuth;*/

  const auth = localStorage.getItem("session");
  const needAuth = to.meta.requireAuth;

  if (needAuth && !auth) {
    next("/signup");
  } else {
    next();
  }
});

export default router;
