<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="show" persistent width="850">
        <v-card>
          <v-card-title>
            <span class="text-h5">Información Detallada</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    label="Nombre"
                    :model-value="props.dataEdit.nombre"
                    variant="solo"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    label="Apellido Paterno"
                    :model-value="props.dataEdit.apellido"
                    variant="solo"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    label="Apellido Materno"
                    :model-value="props.dataEdit.apellido_mat"
                    variant="solo"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    label="Telefono"
                    :model-value="props.dataEdit.telefono"
                    variant="solo"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    label="Email"
                    :model-value="props.dataEdit.email"
                    variant="solo"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    label="Rol"
                    :model-value="props.dataEdit.rol"
                    variant="solo"
                    readonly
                  ></v-text-field>
                </v-col>

               
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="btn me-4 bg-danger"
              color="white"
              variant="text"
              @click="close()"
            >
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script setup>
import { computed } from "vue";

const show = computed({
  // getter
  get() {
    return props.banView;
  },
  // setter
  set() {
    // Note: we are using destructuring assignment syntax here.
    //emit("changeView")
  },
});

const props = defineProps(["banView", "dataEdit"]);
const emit = defineEmits(["changeView"]);

const close = () => {
  emit("changeView");
};
</script>

<style scoped></style>
