<template>
  <div>
    <!--<v-btn @click="dialog = true" class="btn" prepend-icon="mdi-sticker-plus">
        <template v-slot:prepend>
          <v-icon color="success"></v-icon>
        </template>
        Nuevo Registro
      </v-btn>-->

    <v-dialog v-model="show" max-width="600">
      <v-card prepend-icon="mdi-comment-edit" title="Editar Registro">
        <v-form @submit.prevent="validate()" v-model="isFormValid">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Titulo"
                  v-model="formData.titulo"
                  variant="solo"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6">
                <v-text-field
                  label="Descripción"
                  v-model="formData.descripcion"
                  variant="solo"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  label="Cuerpo"
                  v-model="formData.cuerpo"
                  variant="solo"
                ></v-textarea>
              </v-col>

              <v-col cols="12">
                <v-file-input
                  label="Selecciona una imagen"
                  v-model="formData.imagen"
                  variant="solo"
                ></v-file-input>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Ingresa el enlace"
                  v-model="formData.enlace"
                  variant="solo"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="btn me-4 bg-primary"
              text="Editar"
              type="submit"
            ></v-btn>
            <v-btn
              class="btn ms-auto bg-red"
              text="Cancelar"
              @click="close()"
            ></v-btn>
          </v-card-actions>
        </v-form>
        <!--<template v-slot:actions>
            <v-btn
              class="ms-auto bg-red"
              text="Cancelar"
              @click="dialog = false"
            ></v-btn>
          </template>-->
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { computed, onUpdated, reactive, ref } from "vue";

const isFormValid = ref(false);

const formData = reactive({
  id: 0,
  titulo: "",
  descripcion: "",
  cuerpo: "",
  imagen: "",
  enlace: "",
});

const validate = () => {
  if (isFormValid.value) {
    const form = new FormData();

    form.append("id", formData.id);
    form.append("titulo", formData.titulo);
    form.append("descripcion", formData.descripcion);
    form.append("cuerpo", formData.cuerpo);
    form.append("enlace", formData.enlace);
    form.append("imagen", formData.imagen);

    emit("edit",formData.id, form);
    emit("changeEdit");
  }
};

const show = computed({
  // getter
  get() {
    return props.banEdit;
  },
  // setter
  set() {
    // Note: we are using destructuring assignment syntax here.
    //handleReset();
    //emit("changeDialog")
  },
});

const close = () => {
  //handleReset();
  emit("changeEdit");
};

onUpdated(() => {
  formData.id = props.dataEdit.id;
  formData.titulo = props.dataEdit.titulo;
  formData.descripcion = props.dataEdit.descripcion;
  formData.cuerpo = props.dataEdit.cuerpo;
  formData.enlace = props.dataEdit.enlace;
});

/* EMITS */
const emit = defineEmits(["edit", "changeEdit"]);

/* PROPS */
const props = defineProps(["banEdit", "dataEdit"]);
</script>

<style scoped>
form {
  padding: 10px 10px;
}
</style>
