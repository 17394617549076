<template>
  <div>
    <v-form @submit.prevent="returnSelected()" v-model="isFormValid">
      <div class="btns">
        <div class="btn">
          <v-btn
            prepend-icon="mdi-download-box"
            type="button"
            @click="getData()"
          >
            <template v-slot:prepend>
              <v-icon color="warning"></v-icon>
            </template>
            Descargar Información
          </v-btn>
        </div>

        <div class="btn">
          <v-btn prepend-icon="mdi-book-edit" type="submit">
            <template v-slot:prepend>
              <v-icon color="success"></v-icon>
            </template>
            Realizar Cambio
          </v-btn>
        </div>
      </div>

      <v-row>
        <v-col cols="12" md="4">
          <v-select
            label="Selecciona un Estatus"
            v-model="opcionStatus"
            :items="items"
            :rules="estatusR"
            variant="solo"
          ></v-select>
        </v-col>
      </v-row>
    </v-form>

    <v-data-table
      class="mi-tabla"
      v-model="selected"
      :headers="props.headers"
      :items="props.data"
      item-value="id"
      items-per-page="5"
      show-select
      no-data-text="No existen datos"
    >
      <template v-slot:no-data>
        <v-alert :value="true" color="warning" type="warning">
          Lo siento, no hay información disponible . . .
        </v-alert>
      </template>
      
      <template #[`item.aperturaFecha`]="{ item }">
        <p>{{ formatDate(item.aperturaFecha) }}</p>
      </template>

      <template #[`item.status`]="{ item }">
        <div class="text-center">
          <v-chip
            :color="colorStatus(item.status)"
            variant="flat"
            :text="item.status"
          >
          </v-chip>
        </div>
      </template>
    </v-data-table>

    <!--<p>{{ model }}</p>
    <pre>{{ selected }}</pre>-->
  </div>
</template>

<script setup>
import { ref } from "vue";
import Swal from "sweetalert2";

const items = [
  "En Revisión",
  "Pendiente",
  "En Proceso",
  "Aceptada",
  "Rechazada",
  "Cancelada",
];
const opcionStatus = ref(null);
let isFormValid = ref(false);

let selected = ref([]);

/* PROPS Y EMMITS */
const props = defineProps(["headers", "data"]);
const emit = defineEmits(["getSelected", "getExcel"]);

const estatusR = [
  (value) => {
    if (value) return true;

    return "Campo Requerido";
  },
];

const colorStatus = (status) => {
  let color = "";
  switch (status) {
    case "En Revisión":
      color = "yellow-darken-1";
      break;
    case "Pendiente":
      color = "orange";
      break;
    case "En Proceso":
      color = "light-blue-darken-1";
      break;
    case "Aceptada":
      color = "success";
      break;
    case "Rechazada":
    case "Cancelada":
      color = "red-accent-4";
      break;
  }
  return color;
};

const returnSelected = () => {
  if (isFormValid.value && selected.value.length > 0) {
    emit("getSelected", selected.value, opcionStatus.value);
    selected.value = [];
    opcionStatus.value = null;
  } else {
    Swal.fire({
      position: "top-end",
      title: "Oops...",
      text: "Estatus y/o Registros solicitados",
      icon: "error",
      showConfirmButton: false,
      timer: 2500,
    });
  }
};

const formatDate = (fecha) => {
  //console.log(fecha);

  if (fecha == null) {
    return "No Asignado";
  }

  var opciones = { year: "numeric", month: "short", day: "numeric" };
  var date = new Date(fecha)
    .toLocaleDateString("es", opciones)
    .replace(/ /g, "-")
    .replace(".", "")
    .replace(/-([a-z])/, function (x) {
      return "-" + x[1].toUpperCase();
    });

  return date;
};

const getData = () => {
  if (selected.value.length > 0) {
    emit("getExcel", selected.value);
    selected.value = [];
    opcionStatus.value = null;
  } else {
    Swal.fire({
      position: "top-end",
      title: "Oops...",
      text: "Selecciona Registros",
      icon: "error",
      showConfirmButton: false,
      timer: 2500,
    });
  }
};
</script>

<style scoped>
.btns {
  float: left;
  padding: 5px 20px;
}
</style>
