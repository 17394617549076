<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="show" persistent width="850">
        <!--<template v-slot:activator="{ props }">
          <v-btn color="primary" v-bind="props"> Open Dialog </v-btn>
        </template>-->
        <v-card>
          <v-card-title>
            <span class="text-h5">Datos Usuario</span>
          </v-card-title>
          <v-card-text>
            <v-form
              @submit.prevent="validate()"
              v-model="isFormValid"
              ref="form"
            >
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      label="Nombre (s)"
                      v-model="formData.nombre"
                      :rules="nombreR"
                      variant="solo"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      label="Apellido Paterno"
                      v-model="formData.apellidoPat"
                      :rules="apellidosR"
                      variant="solo"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      label="Apellido Materno"
                      v-model="formData.apellidoMat"
                      :rules="apellidosR"
                      variant="solo"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-select
                      label="Selecciona un Rol"
                      :items="roles"
                      :rules="rolR"
                      variant="solo"
                      v-model="formData.rol"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      type="number"
                      label="Teléfono"
                      v-model="formData.telefono"
                      :rules="telefonoR"
                      variant="solo"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      type="email"
                      label="Email"
                      v-model="formData.email"
                      :rules="emailR"
                      variant="solo"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-text-field
                      type="password"
                      label="Contraseña"
                      v-model="formData.password"
                      :rules="passwordR"
                      variant="solo"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-text-field
                      type="password"
                      label="Confirmar Contraseña"
                      v-model="formData.confirm"
                      :rules="[
                        formData.confirm === formData.password ||
                          'Las contraseñas no coinciden',
                      ]"
                      variant="solo"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="btn me-4 bg-green"
                  text="Agregar"
                  type="submit"
                ></v-btn>
                <v-btn
                  class="btn ms-auto bg-red"
                  text="Cancelar"
                  @click="close()"
                ></v-btn>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script setup>
import { computed, reactive, ref } from "vue";

const isFormValid = ref(false);
const form = ref(null);

const formData = reactive({
  nombre: "",
  apellidoPat: "",
  apellidoMat: "",
  email: "",
  telefono: "",
  rol: "",
  password: "",
  confirm: "",
});

//REGLAS

const nombreR = [
  (value) => {
    if (value) return true;

    return "Campo Requerido";
  },
];

const apellidosR = [
  (value) => {
    if (value) return true;

    return "Campo Requerido";
  },
];

const rolR = [
  (value) => {
    if (value) return true;

    return "Campo Requerido";
  },
];

const telefonoR = [
  (value) => {
    if (value) return true;

    return "Campo Requerido";
  },
  (value) => {
    if (value?.length == 10) return true;

    return "Solo 10 dígitos";
  },
];

const emailR = [
  (value) => {
    if (value) return true;

    return "Campo Requerido.";
  },
  (value) => {
    if (/.+@.+\..+/.test(value)) return true;

    return "Ingresa un correo valido.";
  },
];

const passwordR = [
  (value) => {
    if (value) return true;
    return "Campo Requerido.";
  },
  (value) => {
    if (value && value.toLowerCase() !== value) return true;
    return "Al menos una letra mayúscula";
  },
  (value) => {
    if (value && value.toUpperCase() !== value) return true;
    return "Al menos una letra minúscula";
  },
  (value) => {
    if (/\d/.test(value)) return true;
    return "Al menos un  número";
  },
  (value) => {
    if (/[^A-Za-z0-9]/.test(value)) return true;
    return "Al menos un simbolo";
  },
  (value) => {
    if (value && value.length >= 8) return true;
    return "Mínimo 8 carácteres";
  },
];

const roles = ["Super Administrador", "Administrador"];

const show = computed({
  // getter
  get() {
    return props.banAdd;
  },
  // setter
  set() {
    // Note: we are using destructuring assignment syntax here.
    //handleReset();
    //emit("changeDialog")
  },
});

const validate = () => {
  //console.log("Bandera Form: " + isFormValid.value);

  if (isFormValid.value) {
    let rol = formData.rol;
    formData.rol = rol == "Administrador" ? 1 : 0; //0 = SuperAdmin - 1 Admin
    //console.log(formData);
    emit("add", formData);
    emit("changeAdd");
    form.value.reset();
  }
};

const close = () => {
  //handleReset();
  form.value.reset();
  emit("changeAdd");
};

/* EMITS */
const emit = defineEmits(["add", "changeAdd", "add"]);

/* PROPS */
const props = defineProps(["banAdd"]);
</script>

<style scoped></style>
