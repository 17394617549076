<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="show" persistent width="800">
        <v-card>
          <v-card-title>
            <span class="text-h5">Información Detallada</span>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Titulo"
                    :model-value="props.dataEdit.titulo"
                    variant="solo"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    label="Descripcion"
                    :model-value="props.dataEdit.descripcion"
                    variant="solo"
                    readonly
                  ></v-textarea>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    label="Enlace"
                    :model-value="props.dataEdit.enlace"
                    variant="solo"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    label="Cuerpo"
                    :model-value="props.dataEdit.cuerpo"
                    variant="solo"
                    readonly
                    rows="4"
                    row-height="30"
                    auto-grow
                  ></v-textarea>
                </v-col>

                <v-col cols="12">
                  <v-img
                    label="Imagen"
                    width="300"
                    aspect-ratio="16/9"
                    cover
                    :src="`https://apirpm.solopruebas.nl/resources/${imagen}`"
                  ></v-img>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="btn me-4 bg-danger"
              color="white"
              variant="text"
              @click="close()"
            >
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script setup>
import { computed, onUpdated, ref } from "vue";

//let url = "https://apirpm.solopruebas.nl/resources/";
//let url = "https://cdn.vuetifyjs.com/images/parallax/material.jpg";
let imagen = ref("");

const show = computed({
  // getter
  get() {
    return props.banView;
  },
  // setter
  set() {
    // Note: we are using destructuring assignment syntax here.
    //emit("changeView")
    //url = "https://apirpm.solopruebas.nl/resources/";
  },
});

onUpdated(() => {
  /*url += props.dataEdit.imagen;
  console.log("IMAGEN: "+url);*/
  let text = props.dataEdit.imagen;
  const myArray = text.split("/");
  imagen.value = myArray[myArray.length - 1];
});

const props = defineProps(["banView", "dataEdit"]);
const emit = defineEmits(["changeView"]);

const close = () => {
  emit("changeView");
};
</script>

<style scoped></style>
