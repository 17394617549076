<script setup>
import { computed } from "vue";

const show = computed({
  // getter
  get() {
    return props.banView;
  },
  // setter
  set() {
    // Note: we are using destructuring assignment syntax here.
    //emit("changeView")
  },
});

const props = defineProps(["banView", "dataView"]);
const emit = defineEmits(["changeView"]);

const close = () => {
  emit("changeView");
};
</script>

<template>
  <v-container fluid>
    <v-row justify="center">
      <v-dialog v-model="show" persistent width="800">
        <!--<template v-slot:activator="{ props }">
          <v-btn color="primary" v-bind="props"> Open Dialog </v-btn>
        </template>-->
        <v-card>
          <v-card-title>
            <span class="text-h5">Datos Usuario</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    label="Nombre (s)"
                    :model-value="props.dataView.nombre"
                    variant="solo"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    label="Apellido Paterno"
                    :model-value="props.dataView.apellido"
                    variant="solo"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    label="Apellido Materno"
                    :model-value="props.dataView.apellido_mat"
                    variant="solo"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    label="Código Usuario"
                    :model-value="props.dataView.codigo_usuario"
                    variant="solo"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    label="Teléfono"
                    :model-value="props.dataView.telefono"
                    variant="solo"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    label="Pagado"
                    :model-value="props.dataView.pagado ? 'SI' : 'NO'"
                    variant="solo"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    label="Email"
                    :model-value="props.dataView.correo"
                    variant="solo"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" >
                  <v-text-field
                    label="Banco"
                    :model-value="props.dataView.banco"
                    variant="solo"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    label="No. Cuenta"
                    :model-value="props.dataView.cuenta"
                    variant="solo"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    label="Clabe Interbancaria"
                    :model-value="props.dataView.clabe"
                    variant="solo"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="btn me-4 bg-danger"
              color="white"
              variant="text"
              @click="close()"
            >
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<style scoped></style>
