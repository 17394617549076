import axios from "axios";

class CursosService {
  url = "https://apirpm.solopruebas.nl";
  headers = {
    /*"Access-Control-Allow-Headers":
      "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
    "Access-Control-Allow-Origin": "*",*/
    "Content-Type": "multipart/form-data", //"Content-Type": "application/json",
    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
  };

  constructor() {}

  async getData() {
    const res = axios({
      url: this.url + "/api/courses",
      method: "GET",
      //headers: this.headers,
    });

    return res;
  }

  async getId(id) {
    const res = axios({
      url: this.url + "/api/courses/" + id,
      method: "GET",
      //headers: this.headers,
    });

    return res;
  }

  async addRow(datos) {
    const res = axios({
      url: this.url + "/api/courses",
      method: "POST",
      headers: this.headers,
      data: datos,
    });

    return res;
  }

  async EditRow(id, datos) {
    const res = axios({
      url: this.url + "/api/courses/" + id,
      method: "PUT",
      headers: this.headers,
      data: datos,
    });

    return res;
  }

  async DeleteRow(id) {
    const res = axios({
      url: this.url + "/api/courses/" + id,
      method: "DELETE",
      headers:{
        'Authorization': 'Bearer ' + localStorage.getItem('jwt')
      },
    });

    return res;
  }
}

export default CursosService;
