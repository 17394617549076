<template>
  <div>
    <v-card title="Búsqueda" flat>
      <template v-slot:text>
        <v-text-field
          v-model="search"
          label="Search"
          prepend-inner-icon="mdi-magnify"
          variant="outlined"
          hide-details
          single-line
        ></v-text-field>
      </template>

      <v-data-table
        class="mi-tabla"
        :headers="props.headers"
        :items="props.datos"
        item-key="id"
        items-per-page="5"
        :search="search"
      >
        <template v-slot:no-data>
          <v-alert :value="true" color="warning" type="warning">
            Lo siento, no hay información disponible . . .
          </v-alert>
        </template>
        
        <template #[`item.nombre`]="{ item }">
          <p>{{ item.nombre }} {{ item.apellido }} {{ item.apellido_mat }}</p>
        </template>

        <template #[`item.fecha_registro`]="{ item }">
          <p>{{ filterDate(item.fecha_registro) }}</p>
        </template>

        <template #[`item.pagado`]="{ item }">
          <div v-if="!item.pagado">
            <v-btn
              density="compact"
              icon="mdi-account-credit-card"
              color="danger"
              @click="pagar(item.id)"
            ></v-btn>
          </div>
          <div v-else>Pagado!</div>
        </template>

        <template #[`item.detalles`]="{ item }">
          <div>
            <v-btn
              density="compact"
              icon="mdi-eye"
              color="green"
              @click="ver(item.id)"
            ></v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps(["headers", "datos"]);
const emit = defineEmits(["getIdUser", "simularPagar"]);

let search = ref("");

const ver = (id) => {
  emit("getIdUser", id);
};

const pagar = (id) => {
  emit("simularPagar", id);
};

const filterDate = (date) => {
  return date.slice(0, 10);
};
</script>

<style scoped></style>
