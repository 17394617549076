<template>
  <div>
    <v-dialog v-model="show" max-width="600">
      <v-card prepend-icon="mdi-comment-edit" title="Editar Registro">
        <form @submit.prevent="submit">
          <v-text-field
            v-model="titulo.value.value"
            :error-messages="titulo.errorMessage.value"
            label="Titulo"
          ></v-text-field>

          <v-text-field
            v-model="descripcion.value.value"
            :error-messages="descripcion.errorMessage.value"
            label="Descripción"
          ></v-text-field>

          <v-textarea
            v-model="cuerpo.value.value"
            :error-messages="cuerpo.errorMessage.value"
            label="Cuerpo"
          ></v-textarea>

          <v-file-input
            v-model="imagen"
            
            label="Selecciona una imagen"
          ></v-file-input>

          <v-file-input
            v-model="archivo"
            
            label="Selecciona un archivo"
          ></v-file-input>

          <v-btn class="me-4 bg-primary" type="submit"> Editar </v-btn>
          <v-btn
            class="ms-auto bg-red"
            text="Cancelar"
            @click="close()"
          ></v-btn>
          
        </form>
       
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { onMounted, computed, onUpdated,ref } from "vue";
import { useField, useForm } from "vee-validate";

const show = computed({
  // getter
  get() {
    return props.banEdit;
  },
  // setter
  set() {
    // Note: we are using destructuring assignment syntax here.
    handleReset();
    //emit("changeDialog")
  },
});

//handleSubmit, handleReset
const { handleSubmit, handleReset } = useForm({
  validationSchema: {
    titulo(value) {
      if (value?.length >= 2) return true;

      return "Campo Requerido";
    },
    descripcion(value) {
      if (value?.length >= 2) return true;

      return "Campo Requerido";
    },
    cuerpo(value) {
      if (value?.length >= 2) return true;

      return "Campo Requerido";
    },
    /*archivo(value) {
      if (value != "") return true;

      return "Selecciona un archivo";
    },
    imagen(value) {
      if (value?.length >= 1) return true;

      return "Selecciona una imagen.";
    },*/
  },
});

const titulo = useField("titulo");
const descripcion = useField("descripcion");
const cuerpo = useField("cuerpo");
//const archivo = useField("archivo");
//const imagen = useField("imagen");
const archivo = ref("");
const imagen = ref("");

//let id = ref(0);

const submit = handleSubmit((values) => {
  show.value = false;
  //console.log(JSON.stringify(values, null, 2));
  const form = new FormData();
  form.append("titulo", values.titulo);
  form.append("descripcion", values.descripcion);
  form.append("cuerpo", values.cuerpo);

  //form.append("imagen", imagen[0]);
  //form.append("archivo", archivo[0]);
  console.log(imagen.value);
  console.log(archivo.value);
  form.append("imagen", imagen.value);
  form.append("archivo", archivo.value);

  emit("edit", props.dataEdit.id, form);
  emit("changeEdit");
});

const close = () => {
  handleReset();
  emit("changeEdit");
};

onMounted(() => {});

onUpdated(() => {
  //id.value = props.dataEdit.id;

  titulo.value.value = props.dataEdit.titulo;
  descripcion.value.value = props.dataEdit.descripcion;
  cuerpo.value.value = props.dataEdit.cuerpo;
});

/* EMITS */
const emit = defineEmits(["edit", "changeEdit"]);

/* PROPS */
const props = defineProps(["banEdit", "dataEdit"]);
</script>

<style scoped>
form {
  padding: 10px 10px;
}
</style>
