<script setup>
import { onBeforeMount, ref } from "vue";
import Swal from "sweetalert2";

import tablaComponent from "./componentes/tablaComponent.vue";
import modalAdd from "./componentes/modalAdd.vue";
import modalEdit from "./componentes/modalEdit.vue";
import modalView from "./componentes/modalView.vue";
import btnModalComponent from "./componentes/btnModalComponent.vue";

import CursosService from "../services/CursosService";

const service = new CursosService();

let headers = [
  {
    title: "#",
    align: "center",
    sortable: false,
    key: "id",
  },
  { title: "NOMBRE", key: "titulo", sortable: false, align: "center" },
  {
    title: "DESCRIPCIÓN",
    key: "descripcion",
    sortable: false,
    align: "center",
  },
  { title: "OPCIONES", key: "actions", sortable: false, align: "center" },
];

let datos = ref([]);
let dataEdit = ref({});
let banAdd = ref(false); //modal
let banEdit = ref(false); //saber si editar o no
let banView = ref(false);

onBeforeMount(() => {
  //se consume al api para obtener los datos
  getAll();
});

const getAll = () => {
  service
    .getData()
    .then((response) => {
      if (response.data.success) {
        datos.value = response.data.data;
        //console.log(response.data.data);
      } else {
        msg("ERROR!", response.data.message, "error", 2000);
      }
    })
    .catch((error) => {
      console.log(error);
      msg("ERROR!", error.response.data.message, "error", 2000);
    });
};

const changeAdd = () => {
  banAdd.value = !banAdd.value;
};

const changeEdit = () => {
  banEdit.value = !banEdit.value;
};

const changeView = () => {
  banView.value = !banView.value;
};

const getDataView = (idView) => {
  service
    .getId(idView)
    .then((response) => {
      if (response.data.success) {
        dataEdit.value = response.data.data;
        //console.log(response.data.data);
        changeView();
      } else {
        msg("ERROR!", response.data.message, "error", 2000);
      }
    })
    .catch((error) => {
      console.log(error);
      msg("ERROR!", error.response.data.message, "error", 2000);
    });
};

const add = (data) => {
  service
    .addRow(data)
    .then((response) => {
      if (response.data.success) {
        msg("REGISTRADO!", "Registro Exitoso", "success", 2000);
        getAll();
      } else {
        msg("ERROR!", response.data.message, "error", 2000);
      }
    })
    .catch((error) => {
      console.log(error);
      msg("ERROR!", error.response.data.message, "error", 2000);
    });
};

const getDataEdit = (idEdit) => {
  service
    .getId(idEdit)
    .then((response) => {
      if (response.data.success) {
        dataEdit.value = response.data.data;
        changeEdit();
      } else {
        msg("ERROR!", response.data.message, "error", 2000);
      }
    })
    .catch((error) => {
      console.log(error);
      msg("ERROR!", error.response.data.message, "error", 2000);
    });
};

const getIdDelete = (id) => {
  Swal.fire({
    title: "¿Estás Seguro de Eliminar?",
    text: "Si eliminas no podras revertir esta acción!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#0DDE3D", //3085d6
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Eliminar",
    cancelButtonText: "No, Cancelar",
  }).then((result) => {
    if (result.isConfirmed) {
      service
        .DeleteRow(id)
        .then((response) => {
          if (response.data.success) {
            msg("ELIMINADO!", "Registro Eliminado", "success", 1000);
            getAll();
          } else {
            msg("ERROR!", response.data.message, "error", 2000);
          }
        })
        .catch((error) => {
          console.log(error);
          msg("ERROR!", error.response.data.message, "error", 2000);
        });
    } else {
      msg("CANCELADO!", "Operacion Cancelada", "warning", 1000);
    }
  });
};

const edit = (id,data) => {
  service
    .EditRow(id, data)
    .then((response) => {
      if (response.data.success) {
        getAll();
        msg("EDITADO!", "Registro Editado", "success", 2000);
      } else {
        msg("ERROR!", response.data.message, "error", 2000);
      }
    })
    .catch((error) => {
      //console.log(error);
      msg("ERROR!", error.response.data.message, "error", 2000);
    });
};

const msg = (title, text, icon, timer) => {
  Swal.fire({
    position: "top-center",
    title: title,
    text: text,
    icon: icon,
    showConfirmButton: false,
    timer: timer,
  });
};
</script>

<template>
  <div class="py-4 container-fluid">
    <btnModalComponent
      @changeAdd="changeAdd"
      texto="Nuevo Registro"
      icono="mdi-sticker-plus"
    />
    <hr />

    <modalAdd @add="add" :banAdd="banAdd" @changeAdd="changeAdd" />

    <modalEdit
      @edit="edit"
      @changeEdit="changeEdit"
      :banEdit="banEdit"
      :dataEdit="dataEdit"
    />

    <modalView
      :banView="banView"
      :dataEdit="dataEdit"
      @changeView="changeView"
    />

    <tablaComponent
      :headers="headers"
      :datos="datos"
      @getIdDelete="getIdDelete"
      @getDataEdit="getDataEdit"
      @getDataView="getDataView"
    />
  </div>
</template>

<style scoped></style>
