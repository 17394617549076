<template>
  <div class="col-lg-6 mb-lg mb-4">
    <div class="card">
      <div class="p-3 pb-0 card-header">
        <h6 class="mb-0">{{ props.titulo }}</h6>
      </div>
      <div class="p-3 card-body">
        <VueApexCharts
          ref="grafica"
          title="Sales Overview"
          type="bar"
          :options="chartOptions.options"
          :series="chartOptions.series"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, onUpdated, computed, ref } from "vue";
import VueApexCharts from "vue3-apexcharts";

const chartOptions = computed(() => {
  const labels = props.labels;
  const data = props.data;

  return {
    options: {
      chart: {
        id: "vuechart-afiliacion",
      },
      xaxis: {
        categories: labels,
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            let numero = formatterMoney(val);
            return numero;
          },
        },
      },
    },
    series: data, //[{ data }]
  };
});

const formatterMoney = (value) => {
  const dollar = currencyFormatter({
    currency: "USD",
    value,
  });

  return dollar;
};

function currencyFormatter({ currency, value }) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    minimumFractionDigits: 2,
    currency,
  });
  return formatter.format(value);
}

const grafica = ref(null);

/* PROPS */
const props = defineProps(["titulo", "labels", "data"]);

/*let options = {
  chart: {
    id: "vuechart-afiliacion",
  },
  xaxis: {
    categories: ["16/2024", "17/2024", "18/2024"],
  },
};

let series = [
  {
    name: "Total",
    data: [44, 55, 57],
  },
  {
    name: "Total",
    data: [44, 55, 57],
  },
  {
    name: "Pagados",
    data: [76, 85, 101],
  },
  {
    name: "No Pagados",
    data: [35, 41, 36],
  },
];*/


onBeforeMount(() => {

});

onUpdated(() => {
 
});
</script>

<style scoped></style>
