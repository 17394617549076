<script setup>
import { ref, onBeforeMount } from "vue";
import tablaUsuariosComponent from "./componentes/tablaUsuariosComponent.vue";
import modalViewUsr from "./componentes/modalViewUsr.vue";
import UserService from "../services/UserService";

import Swal from "sweetalert2";

const service = new UserService();

let datos = ref([]);
let dataView = ref({});

let banView = ref(false);

let headers = [
  {
    title: "CÓDIGO USUARIO",
    align: "center",
    sortable: false,
    key: "codigo_usuario",
  },
  { title: "NOMBRE", key: "nombre", sortable: false, align: "center" },
  {
    title: "FECHA REGISTRO",
    key: "fecha_registro",
    sortable: false,
    align: "center",
  },
  //{ title: "Saldo Total", key: "saldo", sortable: false, align: "center" },
  { title: "PAGADO", key: "pagado", sortable: false, align: "center" },
  { title: "DETALLES", key: "detalles", sortable: false, align: "center" },
];

onBeforeMount(() => {
  //se consume al api para obtener los datos
  getAll();
});

const getAll = () => {
  service
    .getData()
    .then((response) => {
      //console.log(response.data.data);
      if (response.data.success) {
        datos.value = response.data.data;
      } else {
        msg("ERROR!", response.data.message, "error", 2000);
      }
    })
    .catch((error) => {
      console.log(error);
      msg("ERROR!", error.response.data.message, "error", 2000);
    });
};

const changeView = () => {
  banView.value = !banView.value;
};

const simularPagar = (id) => {
  service
    .pagar({
      userId: id,
      isApproved: true,
      reason: "Simulando Pago",
    })
    .then((response) => {
      console.log(response.data.data);
      getAll();
      /*if (response.data.success) {
        datos.value = response.data.data;
      } else {
        msg("ERROR!", response.data.message, "error", 2000);
      }*/
    })
    .catch((error) => {
      console.log(error);
    });
};

const getIdUser = (id) => {
  service
    .getId(id)
    .then((response) => {
      //console.log(response);
      if (response.data.success) {
        dataView.value = response.data.data;
        //console.log(dataView);
        changeView();
      } else {
        msg("ERROR!", response.data.message, "error", 2000);
      }
    })
    .catch((error) => {
      console.log(error);
      msg("ERROR!", error.response.data.message, "error", 2000);
    });
  //changeView();
};

const msg = (title, text, icon, timer) => {
  Swal.fire({
    position: "top-center",
    title: title,
    text: text,
    icon: icon,
    showConfirmButton: false,
    timer: timer,
  });
};
</script>

<template>
  <div class="py-4 container-fluid">
    <modalViewUsr
      :banView="banView"
      @changeView="changeView"
      :dataView="dataView"
    />

    <tablaUsuariosComponent
      :datos="datos"
      :headers="headers"
      @getIdUser="getIdUser"
      @simularPagar="simularPagar"
    />
  </div>
</template>

<style scoped></style>
