import axios from "axios";

class DashboardService {
  url = "https://apirpm.solopruebas.nl";
  headers = {
    "Content-Type": "application/json",
    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
  };

  async getData() {
    const res = axios({
      url: this.url + "/api/Admins/Dashboard",
      method: "GET",
      headers: this.headers,
    });

    return res;
  }
  
}

export default DashboardService;
