<template>
  <div>
    <v-dialog v-model="show" max-width="600">
      <v-card prepend-icon="mdi-sticker-plus" title="Nuevo Registro">
        <form @submit.prevent="submit">
          <v-text-field
            v-model="titulo.value.value"
            :counter="10"
            :error-messages="titulo.errorMessage.value"
            label="Titulo"
          ></v-text-field>

          <v-text-field
            v-model="descripcion.value.value"
            :counter="10"
            :error-messages="descripcion.errorMessage.value"
            label="Descripción"
          ></v-text-field>

          <v-textarea
            v-model="cuerpo.value.value"
            :counter="10"
            :error-messages="cuerpo.errorMessage.value"
            label="Cuerpo"
          ></v-textarea>

          <v-file-input
            v-model="imagen.value.value"
            :error-messages="imagen.errorMessage.value"
            label="Selecciona una imagen"
          ></v-file-input>

          <v-file-input
            v-model="archivo.value.value"
            :error-messages="archivo.errorMessage.value"
            label="Archivo"
          ></v-file-input>

          <v-btn class="me-4 bg-green" type="submit"> Agregar </v-btn>
          <v-btn
            class="ms-auto bg-red"
            text="Cancelar"
            @click="close()"
          ></v-btn>
          <!--<v-btn class="me-4" type="submit"> submit </v-btn>
            <v-btn @click="handleReset"> clear </v-btn>-->
        </form>

        <!--<template v-slot:actions>
            <v-btn
              class="ms-auto bg-red"
              text="Cancelar"
              @click="dialog = false"
            ></v-btn>
          </template>-->
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { onMounted, computed } from "vue";
import { useField, useForm } from "vee-validate";

const show = computed({
  // getter
  get() {
    return props.banAdd;
  },
  // setter
  set() {
    // Note: we are using destructuring assignment syntax here.
    handleReset();
    //emit("changeDialog")
  },
});

//handleSubmit, handleReset
const { handleSubmit, handleReset } = useForm({
  validationSchema: {
    titulo(value) {
      if (value) return true;

      return "Campo Requerido";
    },
    descripcion(value) {
      if (value) return true;

      return "Campo Requerido";
    },
    cuerpo(value) {
      if (value) return true;

      return "Campo Requerido";
    },
    archivo(value) {
      if (value) return true;

      return "Campo Requerido";
    },
    imagen(value) {
      if (value) return true;

      return "Campo Requerido.";
    },
  },
});

const titulo = useField("titulo");
const descripcion = useField("descripcion");
const cuerpo = useField("cuerpo");
const archivo = useField("archivo");
const imagen = useField("imagen");

const submit = handleSubmit((values) => {
  show.value = false;
  //console.log(JSON.stringify(values, null, 2));
  const form = new FormData();
  form.append("titulo", values.titulo);
  form.append("descripcion", values.descripcion);
  form.append("cuerpo", values.cuerpo);
  form.append("imagen", values.imagen[0]);
  form.append("archivo", values.archivo[0]);

  emit("add", form);
  emit("changeAdd");
});

const close = () => {
  handleReset();
  emit("changeAdd");
};

onMounted(() => {});

/* EMITS */
const emit = defineEmits(["add", "changeAdd"]);

/* PROPS */
const props = defineProps(["banAdd"]);
</script>

<style scoped>
form {
  padding: 10px 10px;
}
</style>
