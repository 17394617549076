<template>
  <div class="col-lg-6 mb-lg mb-4">
    <div class="card">
      <div class="p-3 pb-0 card-header">
        <h6 class="mb-0">{{ props.titulo }}</h6>
      </div>
      <div class="p-3 card-body">
        <VueApexCharts
          ref="grafica"
          title="Sales Overview"
          type="bar"
          :options="chartOptions.options"
          :series="chartOptions.series"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import VueApexCharts from "vue3-apexcharts";

const props = defineProps(["titulo", "labels", "data"]);

const chartOptions = computed(() => {
  const labels = props.labels;
  const data = props.data;

  return {
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          borderRadiusApplication: "end", // 'around', 'end'
          borderRadiusWhenStacked: "last", // 'all', 'last'
          /*dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },*/
        },
      },
      xaxis: {
        categories: labels,
      },
      legend: {
        position: "right",
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
    },
    series: data, //[{ data }]
  };
});
/*let series = [
  {
    name: "PRODUCT A",
    data: [44, 55, 41, 67, 22, 43],
  },
  {
    name: "PRODUCT B",
    data: [13, 23, 20, 8, 13, 27],
  },
  {
    name: "PRODUCT C",
    data: [11, 17, 15, 15, 21, 14],
  },
  {
    name: "PRODUCT D",
    data: [21, 7, 25, 13, 22, 8],
  },
];

let chartOptions = {
  chart: {
    type: "bar",
    height: 350,
    stacked: true,
    toolbar: {
      show: true,
    },
    zoom: {
      enabled: true,
    },
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        legend: {
          position: "bottom",
          offsetX: -10,
          offsetY: 0,
        },
      },
    },
  ],
  plotOptions: {
    bar: {
      horizontal: false,
      borderRadius: 10,
      borderRadiusApplication: "end", // 'around', 'end'
      borderRadiusWhenStacked: "last", // 'all', 'last'
      dataLabels: {
        total: {
          enabled: true,
          style: {
            fontSize: "13px",
            fontWeight: 900,
          },
        },
      },
    },
  },
  xaxis: {
    type: "datetime",
    categories: [
      "01/01/2011 GMT",
      "01/02/2011 GMT",
      "01/03/2011 GMT",
      "01/04/2011 GMT",
      "01/05/2011 GMT",
      "01/06/2011 GMT",
    ],
  },
  legend: {
    position: "right",
    offsetY: 40,
  },
  fill: {
    opacity: 1,
  },
};*/

/*const chartOptions = computed(() => {
  const labels = props.labels;
  const data = props.data;

  return {
    options: {
      chart: {
        id: "vuechart-afiliacion",
      },
      xaxis: {
        categories: labels,
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            let numero = formatterMoney(val);
            return numero;
          },
        },
      },
    },
    series: data, //[{ data }]
  };
});*/
</script>

<style scoped></style>
