<template>
  <div class="py-4 container-fluid">
    <btnModalComponent
      @changeAdd="changeAdd"
      texto="Nuevo Registro"
      icono="mdi-sticker-plus"
    /> <hr>

    <modalAddBook @add="add" :banAdd="banAdd" @changeAdd="changeAdd" />

    <modalEditBook
      @edit="edit"
      @changeEdit="changeEdit"
      :banEdit="banEdit"
      :dataEdit="dataEdit"
    />

    <modalViewBook :banView="banView" :dataEdit="dataEdit" @changeView="changeView"/>

    <tablaComponent
      :headers="headers"
      :datos="datos"
      @getIdDelete="getIdDelete"
      @getDataEdit="getDataEdit"
      @getDataView="getDataView"
    />
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from "vue";
import Swal from "sweetalert2";

import tablaComponent from "./componentes/tablaComponent.vue";
import btnModalComponent from "./componentes/btnModalComponent.vue";
import modalEditBook from "./componentes/modalEditBook.vue";
import modalAddBook from "./componentes/modalAddBook.vue";
import modalViewBook from "./componentes/modalViewBook.vue";
import LibrosService from "../services/LibrosService";

let headers = [
  {
    title: "#",
    align: "center",
    sortable: false,
    key: "id",
  },
  { title: "NOMBRE", key: "titulo", sortable: false, align: "center" },
  {
    title: "DESCRIPCIÓN",
    key: "descripcion",
    sortable: false,
    align: "center",
  },
  { title: "OPCIONES", key: "actions", sortable: false, align: "center" },
];

let datos = ref([]);
let dataEdit = ref({});
let banAdd = ref(false); //modal
let banEdit = ref(false); //saber si editar o no
let banView = ref(false);

const service = new LibrosService();

onBeforeMount(() => {
  //se consume al api para obtener los datos
  getAll();
});

const changeAdd = () => {
  banAdd.value = !banAdd.value;
};

const changeEdit = () => {
  banEdit.value = !banEdit.value;
  console.log("Edit Ban: " + banEdit.value);
};

const changeView = () => {
  banView.value = !banView.value;
};

const getAll = () => {
  service
    .getData()
    .then((response) => {
      //console.log(response.data.data);
      
      if (response.data.success) {
        datos.value = response.data.data;
      } else {
        msg("ERROR!", response.data.message, "error", 2000);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

const add = (data) => {
  console.log(data);
  service
    .addRow(data)
    .then((response) => {
      if (response.data.success) {
        getAll();
        msg("REGISTRADO!", "Registro Exitoso", "success", 2000);
      } else {
        msg("ERROR!", response.data.message, "error", 2000);
      }
    })
    .catch((error) => {
      //console.log(error);
      msg("ERROR!",error.response.data.title, "error", 2000);
    });
};

const getDataView = (idView) => {
  service
    .getId(idView)
    .then((response) => {
      if (response.data.success) {
        dataEdit.value = response.data.data;
        //console.log(dataEdit.value.imagen);
        //console.log(dataEdit.value);
        changeView();
      } else {
        msg("ERROR!", response.data.message, "error", 2000);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

const getDataEdit = (idEdit) => {
  service
    .getId(idEdit)
    .then((response) => {
      if (response.data.success) {
        dataEdit.value = response.data.data;
        changeEdit();
      } else {
        msg("ERROR!", response.data.message, "error", 2000);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

const getIdDelete = (id) => {
  Swal.fire({
    title: "¿Estás Seguro de Eliminar?",
    text: "Si eliminas no podras revertir esta acción!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#0DDE3D", //3085d6
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Eliminar",
    cancelButtonText: "No, Cancelar",
  }).then((result) => {
    if (result.isConfirmed) {
      service
        .DeleteRow(id)
        .then((response) => {
          if (response.data.success) {
            getAll();
            msg("ELIMINADO!", "Registro Eliminado", "success", 1000);
          } else {
            msg("ERROR!", response.data.message, "error", 2000);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      msg("CANCELADO!", "Operacion Cancelada", "warning", 1000);
    }
  });
};

const edit = (id,data) => {
  service
    .EditRow(id, data)
    .then((response) => {
      if (response.data.success) {
        getAll();
        msg("EDITADO!", "Registro Editado", "success", 2000);
      } else {
        msg("ERROR!", response.data.message, "error", 2000);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

const msg = (title, text, icon, timer) => {
  Swal.fire({
    position: "top-center",
    title: title,
    text: text,
    icon: icon,
    showConfirmButton: false,
    timer: timer,
  });
};
</script>

<style scoped></style>
