<template>
  <div class="py-4 container-fluid">
    <btnModalComponent
      @changeAdd="changeAdd"
      texto="Nuevo Registro"
      icono="mdi-sticker-plus"
    />
    <br>

    <modalEditAdmin
      :banEdit="banEdit"
      @changeEdit="changeEdit"
      @edit="edit"
      :getDataEdit="getDataEdit"
      :dataEdit="dataEdit"
    />

    <modalAddAdmin :banAdd="banAdd" @changeAdd="changeAdd" @add="add" />

    <modalViewAdmin
      :banView="banView"
      :dataEdit="dataEdit"
      @changeView="changeView"
    />

    <br />

    <tablaComponent
      :headers="headers"
      :datos="datos"
      @getIdDelete="getIdDelete"
      @getDataEdit="getDataEdit"
      @getDataView="getDataView"
    />
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from "vue";
import Swal from "sweetalert2";

import tablaComponent from "./componentes/tablaComponent.vue";
import modalAddAdmin from "./componentes/modalAddAdmin.vue";
import modalEditAdmin from "./componentes/modalEditAdmin.vue";
import modalViewAdmin from "./componentes/modalViewAdmin.vue";
import btnModalComponent from "./componentes/btnModalComponent.vue";

import AdminService from "../services/AdminService";

const service = new AdminService();

let datos = ref([]);
let banAdd = ref(false); //modal
let banEdit = ref(false); //saber si editar o no
let banView = ref(false);
let dataEdit = ref({});

let headers = [
  { title: "NOMBRE", key: "nombre", sortable: false, align: "center" },
  { title: "ROL", key: "rol", sortable: false, align: "center" },
  { title: "OPCIONES", key: "actions", sortable: false, align: "center" },
];

onBeforeMount(() => {
  //datos.value = arreglo;
  getAll();
});

const getAll = () => {
  service
    .getData()
    .then((response) => {
      //console.log(response.data.data);

      if (response.data.success) {
        datos.value = response.data.data;
      } else {
        msg("ERROR!", response.data.message, "error", 2000);
      }
    })
    .catch((error) => {
      console.log(error);
      msg("ERROR!", error.response.data.message, "error", 2000);
    });
};

const changeAdd = () => {
  banAdd.value = !banAdd.value;
};

const changeEdit = () => {
  banEdit.value = !banEdit.value;
};

const changeView = () => {
  banView.value = !banView.value;
};

const add = (data) => {
  //console.log(data);
  service
    .addRow(data)
    .then((response) => {
      //console.log(response.data.data);
      if (response.data.success) {
        msg("REGISTRADO!", "Registro Exitoso", "success", 2000);
        getAll();
      } else {
        msg("ERROR!", response.data.message, "error", 2000);
      }
    })
    .catch((error) => {
      console.log(error);
      msg("ERROR!", error.response.data.message, "error", 2000);
    });
};

const edit = (id, data) => {
  service
    .updateData(id, data)
    .then((response) => {
      //console.log(response);
      if (response.data.success) {
        msg("EXITO !", "REGISTRO EDITADO!", "success", 2000);
        getAll();
      } else {
        msg("ERROR!", response.data.message, "error", 2000);
      }
    })
    .catch((error) => {
      console.log(error);
      msg("ERROR!", error.response.data.message, "error", 2000);
    });
};

const getDataEdit = (idEdit) => {
  service
    .getId(idEdit)
    .then((response) => {
      //console.log(response);
      if (response.data.success) {
        dataEdit.value = response.data.data;
        changeEdit();
      } else {
        msg("ERROR!", response.data.message, "error", 2000);
      }
    })
    .catch((error) => {
      console.log(error);
      msg("ERROR!", error.response.data.message, "error", 2000);
    });
};

const getIdDelete = (id) => {
  // Metodo listo
  Swal.fire({
    title: "¿Estás Seguro de Eliminar?",
    text: "Si eliminas no podras revertir esta acción!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#0DDE3D", //3085d6
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Eliminar",
    cancelButtonText: "No, Cancelar",
  }).then((result) => {
    if (result.isConfirmed) {
      service
        .delete(id)
        .then((response) => {
          //console.log(response);
          if (response.data.success) {
            msg("ELIMINADO!", "Registro Eliminado", "success", 2000);
            getAll();
          } else {
            msg("ERROR!", response.data.message, "error", 2000);
          }
        })
        .catch((error) => {
          //console.log(error.response.request.status);
          if (error.response.request.status == 405) {
            msg(
              "ERROR!",
              "NO TIENES PERMISOS PARA REALIZAR ESTA ACCION",
              "warning",
              2500
            );
          } else {
            msg("ERROR!", error.response.data.message, "error", 2000);
          }
        });
    } else {
      msg("CANCELADO!", "Operación Cancelada", "warning", 1000);
    }
  });
};

const getDataView = (idView) => {
  service
    .getId(idView)
    .then((response) => {
      if (response.data.success) {
        dataEdit.value = response.data.data;
        //console.log(response.data.data);
        changeView();
      } else {
        msg("ERROR!", response.data.message, "error", 2000);
      }
    })
    .catch((error) => {
      console.log(error);
      msg("ERROR!", error.response.data.message, "error", 2000);
    });
};

const msg = (title, text, icon, timer) => {
  Swal.fire({
    position: "top-center",
    title: title,
    text: text,
    icon: icon,
    showConfirmButton: false,
    timer: timer,
  });
};
</script>

<style scoped></style>
