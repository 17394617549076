import axios from "axios";

class SolicitudesService {

  url = "https://apirpm.solopruebas.nl";
  headers = {
    "Content-Type": "application/json",
    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
  };


  constructor() {}

  async getData() {
    const res = axios({
      url: this.url + "/api/Withdrawal",
      method: "GET",
      headers:this.headers
    });

    return res;
  }

  async updateData(datos) {
    const res = axios({
      url: this.url + "/api/Withdrawal",
      method: "PUT",
      headers:this.headers,
      data:datos
    });

    return res;
  }

  async getExcel(datos) {
    const res = axios({
      url: this.url + "/api/Withdrawal/Download",
      method: "POST",
      responseType: "blob",
      headers:this.headers,
      data:datos
    });

    return res;
  }
  
}

export default SolicitudesService;
