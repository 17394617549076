<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="py-4 container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="car-body">
                    <div class="espacio row">
                      <div class="col-md-12">
                        <label
                          for="example-text-input"
                          class="form-control-label text-lg text-center"
                          >SELECCIONAR FILTRO</label
                        >
                        <v-select
                          v-model="select"
                          :items="items"
                          variant="outlined"
                          @update:modelValue="update()"
                          class=""
                        ></v-select>
                      </div>

                      <div class="col-md-6">
                        <label
                          for="example-text-input"
                          class="form-control-label text-lg text-center"
                          >SELECCIONAR FECHA INICIO</label
                        >
                        <argon-input id="desde" type="date" v-model="desde" />
                      </div>

                      <div class="col-md-6">
                        <label
                          for="example-text-input"
                          class="form-control-label text-lg text-center"
                          >SELECCIONAR FECHA FIN</label
                        >
                        <argon-input id="hasta" type="date" v-model="hasta" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <br />
          <div>
            <div class="py-4 container-fluid">
              <div class="row">
                <div class="col-lg-12">
                  <div class="row">
                    <div class="col-lg-4 col-md-6 col-12">
                      <mini-statistics-card
                        title="BALANCE"
                        :value="formatterMoney(balance)"
                        :icon="{
                          component: 'ni ni-money-coins',
                          background: 'bg-gradient-primary',
                          shape: 'rounded-circle',
                        }"
                      />
                    </div>
                    <div class="col-lg-4 col-md-6 col-12">
                      <mini-statistics-card
                        title="INGRESO"
                        :value="formatterMoney(ingreso)"
                        :icon="{
                          component: 'ni ni-money-coins',
                          background: 'bg-gradient-success',
                          shape: 'rounded-circle',
                        }"
                      />
                    </div>
                    <div class="col-lg-4 col-md-6 col-12">
                      <mini-statistics-card
                        title="EGRESO"
                        :value="formatterMoney(egreso)"
                        :icon="{
                          component: 'ni ni-money-coins',
                          background: 'bg-gradient-danger',
                          shape: 'rounded-circle',
                        }"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <tablaDashboard
            titulo="Retiros"
            :headers="headersRetiros"
            :datos="datosRetiros"
            :dash="false"
            :rep="true"
          />

          <graficaStackComponent
            titulo="Datos Afiliación"
            :labels="cabecerasAfiliacion"
            :data="seriesAfilia"
          />

          <graficaComponent
            titulo="Datos Ingresos"
            :labels="cabecerasIngresos"
            :data="seriesIngresos"
          />

          <graficaComponent
            titulo="Datos Egresos"
            :labels="cabecerasEgresos"
            :data="seriesEgresos"
          />
          <!--<graficaComponent titulo="Datos Egresos" :labels="cabecerasEgresos" :data="seriesEgresos"/>
          <graficaStackComponent titulo="Datos Retiros" :labels="cabecerasAfiliacion" :data="seriesAfilia"/>
          <graficaStackComponent titulo="Datos Balance" :labels="cabecerasAfiliacion" :data="seriesAfilia"/>-->
        </div>

        <!--<v-container>
            <v-row justify="space-around">
              <v-locale-provider locale="es">
                <v-date-picker
                  show-adjacent-months
                  color="green lighten-1"
                  header="DESDE"
                  v-model="desde"
                  title="SELECCIONA LA FECHA INICIO"
                ></v-date-picker>
              </v-locale-provider>
              <v-locale-provider locale="es">
                <v-date-picker
                  show-adjacent-months
                  color="primary"
                  header="HASTA"
                  v-model="hasta"
                  title="SELECCIONA LA FECHA FIN"
                ></v-date-picker>
              </v-locale-provider>
            </v-row>
          </v-container>-->
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import graficaComponent from "./componentes/graficaComponent.vue";
import graficaStackComponent from "./componentes/graficaStackComponent.vue";
import tablaDashboard from "./componentes/tablaDashboard.vue";
import ReportesService from "../services/ReportesService";
import ArgonInput from "@/components/ArgonInput.vue";
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";

const service = new ReportesService();

let desde = ref(null);
let hasta = ref(null);

let select = ref("Día");
let items = ["Día", "Semana", "Mes", "Año"];

let datosAfiliacion = ref([]);
let cabecerasAfiliacion = ref([]);

let datosIngresos = ref([]);
let cabecerasIngresos = ref([]);

let datosEgresos = ref([]);
let cabecerasEgresos = ref([]);

let headersRetiros = [
  { title: "RANGO", align: "center", key: "rango" },
  { title: "SOLICITUDES", align: "center", key: "solicitudes" },
  { title: "SOLICITADO", align: "center", key: "solicitado" },
];

let datosRetiros = ref([]);

let balance = ref(0);
let ingreso = ref(0);
let egreso = ref(0);

onMounted(() => {
  //se consume al api para obtener los datos
  dataAfiliacion(data);
  dataIngresos(data);
  dataEgresos(data);
  dataRetiros(data);
  dataBalance(data);
});

let data = {
  rango: "day",
  limiteRegistros: 0,
  offset: 0,
  desde: null,
  hasta: null,
};

const update = () => {
  //console.log(`Count is: ${select.value}`);
  let bandera = ref(false);

  if (desde.value != null && hasta.value != null) {
    bandera.value = true;
    console.log("si hay fechas: /" + desde.value + "/" + hasta.value);
  }

  let filtro = ref("");
  switch (select.value) {
    case "Día":
      filtro.value = "day";
      break;
    case "Semana":
      filtro.value = "week";
      break;
    case "Mes":
      filtro.value = "month";
      break;
    case "Año":
      filtro.value = "year";
      break;
  }

  data.rango = filtro.value;

  if (bandera.value) {
    data.desde = desde.value;
    data.hasta = hasta.value;
  }

  dataAfiliacion(data);
  dataIngresos(data);
  dataEgresos(data);
  dataRetiros(data);

  document.getElementById("desde").value = "";
  document.getElementById("hasta").value = "";
  desde.value = null;
  hasta.value = null;

  data.desde = null;
  data.hasta = null;
};

const dataAfiliacion = (datos) => {
  service
    .getAfiliacion(datos)
    .then((response) => {
      /*console.log("--------------- AFILIACION ---------------");
      console.log(response.data.data);*/
      datosAfiliacion.value = response.data.data;
      agruparAfiliacion();
    })
    .catch((error) => {
      console.log(error);
      //msg(error.response);
    });
};

const dataIngresos = (datos) => {
  service
    .getIngresos(datos)
    .then((response) => {
      /*console.log("--------------- INGRESOS ---------------");
      console.log(response.data.data);*/
      datosIngresos.value = response.data.data;
      agruparIngresos();
    })
    .catch((error) => {
      console.log(error);
      //msg(error.response);
    });
};

const dataEgresos = (datos) => {
  service
    .getEgreso(datos)
    .then((response) => {
      /*console.log("--------------- EGRESOS ---------------");
      console.log(response.data.data);*/
      datosEgresos.value = response.data.data;
      agruparEgresos();
    })
    .catch((error) => {
      console.log(error);
      //msg(error.response);
    });
};

const dataRetiros = (datos) => {
  service
    .getRetiros(datos)
    .then((response) => {
      /*console.log("--------------- RETIROS ---------------");
      console.log(response.data.data);*/
      datosRetiros.value = response.data.data;
    })
    .catch((error) => {
      console.log(error);
      //msg(error.response);
    });
};

const dataBalance = () => {
  service
    .getBalance()
    .then((response) => {
      /*console.log("--------------- BALANCE ---------------");
      console.log(response);*/
      balance.value = response.data.data.balance;
      ingreso.value = response.data.data.ingreso;
      egreso.value = response.data.data.egreso;
    })
    .catch((error) => {
      console.log(error);
      //msg(error.response);
    });
};

const agruparAfiliacion = () => {
  //declarando auxiliares
  let cabeceras = [];

  //limpiando la data
  seriesAfilia[0].data = []; //Total
  seriesAfilia[1].data = []; //Pagados
  seriesAfilia[2].data = []; //No Pagados

  //ordenando los datos
  datosAfiliacion.value.sort(function (a, b) {
    var textA = a.rango;
    var textB = b.rango;
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

  //console.log(datosAfiliacion.value);

  //agregamos las cabeceras
  datosAfiliacion.value.forEach((element) => {
    //console.log(element.rango);
    cabeceras.push(element.rango);
  });
  //console.log(cabeceras);

  //eliminamos las cabeceras duplicadas
  ///cabeceras.value = Array.from(new Set(cabeceras.value));
  cabeceras = cabeceras.filter((item, index) => {
    return cabeceras.indexOf(item) === index;
  });
  //console.log(cabeceras);

  //afiliacionOpt.xaxis.categories = cabeceras;
  //console.log(afiliacionOpt.xaxis.categories);
  //console.log(seriesAfilia[0].data);

  cabeceras.forEach((element) => {
    let pagado = 0,
      nopagado = 0,
      total = 0;
    //console.log(element);
    datosAfiliacion.value.forEach((item) => {
      if (element == item.rango) {
        if (item.pagado) {
          pagado += item.registrados;
        } else {
          nopagado += item.registrados;
        }
      }
    });
    total = pagado + nopagado;
    seriesAfilia[0].data.push(total); //Total
    seriesAfilia[1].data.push(pagado); //Pagados
    seriesAfilia[2].data.push(nopagado); //No Pagados
  });

  cabecerasAfiliacion.value = cabeceras;

  //console.log(afiliacionOpt);
  //console.log(seriesAfilia);
};

const agruparIngresos = () => {
  //declarando auxiliares
  let cabeceras = [];
  //limpiando la data
  seriesIngresos[0].data = []; //Total

  //ordenando los datos
  datosIngresos.value.sort(function (a, b) {
    var textA = a.rango;
    var textB = b.rango;
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

  //console.log(datosIngresos.value);

  //agregamos las cabeceras
  datosIngresos.value.forEach((element) => {
    //console.log(element);
    //console.log(element.rango);
    cabeceras.push(element.rango);
    seriesIngresos[0].data.push(element.ingreso);
  });

  cabecerasIngresos.value = cabeceras;
};

const agruparEgresos = () => {
  //declarando auxiliares
  let cabeceras = [];
  //limpiando la data
  seriesEgresos[0].data = []; //Total

  //ordenando los datos
  datosEgresos.value.sort(function (a, b) {
    var textA = a.rango;
    var textB = b.rango;
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

  //console.log(datosEgresos.value);

  //agregamos las cabeceras
  datosEgresos.value.forEach((element) => {
    //console.log(element);
    //console.log(element.rango);
    cabeceras.push(element.rango);
    seriesEgresos[0].data.push(element.egreso);
  });

  cabecerasEgresos.value = cabeceras;
};

const formatterMoney = (value) => {
  const dollar = currencyFormatter({
    currency: "USD",
    value,
  });

  return dollar;
};

function currencyFormatter({ currency, value }) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    minimumFractionDigits: 2,
    currency,
  });
  return formatter.format(value);
}

let seriesAfilia = [
  {
    name: "Total",
    data: [],
  },
  {
    name: "Pagados",
    data: [],
  },
  {
    name: "No Pagados",
    data: [],
  },
];

let seriesIngresos = [
  {
    name: "Ingresos",
    data: [],
  },
];

let seriesEgresos = [
  {
    name: "Egresos",
    data: [],
  },
];
</script>

<style scoped>
.espacio {
  padding: 0.3em 0.3em;
}

input {
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background-position: right;
  background-size: auto;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: auto;
}
</style>
