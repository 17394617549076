<template>
  <div>
    <v-card title="Búsqueda" flat>
      <template v-slot:text>
        <v-text-field
          v-model="search"
          label="Search"
          prepend-inner-icon="mdi-magnify"
          variant="outlined"
          hide-details
          single-line
        ></v-text-field>
      </template>

      <v-data-table
        class="mi-tabla"
        :headers="props.headers"
        :items="props.datos"
        item-key="id"
        items-per-page="5"
        :search="search"
      >
        <template v-slot:no-data>
          <v-alert :value="true" color="warning" type="warning">
            Lo siento, no hay información disponible . . .
          </v-alert>
        </template>

        <template #[`item.actions`]="{ item }">
          <div>
            <v-btn
              density="compact"
              icon="mdi-book-edit"
              color="primary"
              @click="edit(item.id)"
            ></v-btn>

            <v-btn
              density="compact"
              icon="mdi-delete"
              color="error"
              @click="eliminar(item.id)"
            ></v-btn>

            <v-btn
              density="compact"
              icon="mdi-eye"
              color="green"
              @click="ver(item.id)"
            ></v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script setup>
import { ref } from "vue";

let search = ref("");
const props = defineProps(["headers", "datos"]);
const emit = defineEmits(["getDataEdit", "getIdDelete"]);

const edit = (id) => {
  emit("getDataEdit", id);
};

const eliminar = (id) => {
  emit("getIdDelete", id);
};

const ver = (id) => {
  emit("getDataView", id);
};
</script>

<style scoped>
.btns {
  display: flex;
  flex-wrap: wrap;
}
</style>
