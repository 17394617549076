<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="row centro">
            <graficaComponent
              titulo="INGRESOS"
              :labels="rangos"
              :data="[
                {
                  name: 'Ingresos',
                  data: ingresos,
                },
              ]"
            />
          </div>
          <div class="row">
            <tablaDashboard
              titulo="RETIROS MAS RECIENTES"
              :headers="headersRetiros"
              :datos="retiros"
              :dash="true" :rep="false"
            />
          </div>
          <div class="row">
            <tablaDashboard
              titulo="USUARIOS RECIEN REGISTRADOS"
              :headers="headersUsuarios"
              :datos="usuarios"
              :dash="true" :rep="false"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from "vue";

import graficaComponent from "./componentes/graficaComponent.vue";
import tablaDashboard from "./componentes/tablaDashboard.vue";
import DashboardService from "../services/DashboardService";

const service = new DashboardService();

let headersRetiros = [
  { title: "USUARIO", align: "center", key: "usuario" },
  { title: "NO. CUENTA", align: "center", key: "numeroCuenta" },
  { title: "FECHA APERTURA", align: "center", key: "aperturaFecha" },
  { title: "CANTIDAD", align: "center", key: "cantidad" },
  { title: "SALDO ACTUAL", align: "center", key: "saldoActual" },
];

let headersUsuarios = [
  {
    title: "CÓDIGO USUARIO",
    align: "center",
    sortable: false,
    key: "codigo_usuario",
  },
  { title: "Nombre", key: "nombre", sortable: false, align: "center" },
  {
    title: "APELLIDO PATERNO",
    key: "apellido",
    sortable: false,
    align: "center",
  },
  {
    title: "APELLIDO MATERNO",
    key: "apellido_mat",
    sortable: false,
    align: "center",
  },
  {
    title: "FECHA REGISTRO",
    key: "fecha_registro",
    sortable: false,
    align: "center",
  },
  { title: "PAGADO", key: "pagado", sortable: false, align: "center" },
];

let retiros = ref([]);
let usuarios = ref([]);

let rangos = [];
let ingresos = [];

let datos = [];

onBeforeMount(() => {
  service
    .getData()
    .then((response) => {
      //console.log(response);
      datos = response.data.data.ingresos;
      retiros.value = response.data.data.retiros;
      usuarios.value = response.data.data.usuarios;

      datos.sort(function (a, b) {
        var textA = a.rango;
        var textB = b.rango;
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });

      datos.forEach((element) => {
        //console.log(element);
        rangos.push(element.rango);
        ingresos.push(element.ingreso);
      });
      /*console.log(rangos);
      console.log(ingresos);*/
    })
    .catch((error) => {
      console.log(error);
    });
});
</script>

<style scoped>
.centro {
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
}
</style>
