<script setup>
import { onBeforeUnmount, onBeforeMount, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2";

//import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import AuthService from "../services/AuthService";

const body = document.getElementsByTagName("body")[0];

const store = useStore();
const router = useRouter();

onBeforeMount(() => {
  store.state.hideConfigButton = true;
  store.state.showNavbar = false;
  store.state.showSidenav = false;
  store.state.showFooter = false;
  body.classList.remove("bg-gray-100");
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  store.state.showNavbar = true;
  store.state.showSidenav = true;
  store.state.showFooter = true;
  body.classList.add("bg-gray-100");
});

const service = new AuthService();
const isFormValid = ref(false);

let email = ref("");

const emailR = [
  (value) => {
    if (value) return true;

    return "Campo Requerido.";
  },
  (value) => {
    if (/.+@.+\..+/.test(value)) return true;

    return "Ingresa un correo valido.";
  },
];

const submit = async () => {
  if (isFormValid.value) {
    service
      .forgotPwd({email:email.value})
      .then((response) => {
        if (response.status) {
          msg("EXITO!", response.data.message, "success", 4000);
          router.push("/");
        }else{
          msg(response.data.message, "", "warning", 4000);
        }
      })
      .catch((error) => {
        console.log("--- MENSAJES DE ERROR ---");
        console.log(error);
        console.log(error.request.status);
        console.log(error.response.data);
        msg("Error", error.response.data.message, "error", 3000);
      });
  }
};

const msg = (title, text, icon, timer) => {
  Swal.fire({
    position: "top-end",
    title: title,
    text: text,
    icon: icon,
    showConfirmButton: false,
    timer: timer,
  });
};
</script>
<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <!--<div class="col-12">
        <navbar isBtn="bg-gradient-light" />
      </div>-->
    </div>
  </div>
  <main class="main-content mt-0">
    <div
      class="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg"
      style="
        background-image: url(&quot;https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signup-cover.jpg&quot;);
        background-position: top;
      "
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <!--<div class="col-lg-5 text-center mx-auto">
            <h1 class="text-white mb-2 mt-5">BIENVENIDO!</h1>
            <p class="text-lead text-white">Ingresa los siguientes datos.</p>
          </div>-->
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
        <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
          <div class="card z-index-0">
            <div class="card-header text-center pt-4">
              <h5>Ingresa tu Correo</h5>
            </div>
            <div class="card-body">
              <v-form
                role="form"
                @submit.prevent="submit()"
                v-model="isFormValid"
              >
                <v-text-field
                  label="Correo"
                  variant="solo"
                  v-model="email"
                  :rules="emailR"
                ></v-text-field>

                <div class="text-center">
                  <v-btn
                    class="btn me-4 bg-green"
                    text="Resetear!"
                    type="submit"
                  ></v-btn>
                </div>
              </v-form>
            </div>

            <div></div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer />
</template>

<style scoped></style>
