<script setup>
import { onBeforeMount,ref } from "vue";
import { useRoute } from "vue-router";
//import { useStore } from "vuex";

import SidenavItem from "./SidenavItem.vue";
//import SidenavCard from "./SidenavCard.vue";

//const store = useStore();
//const isRTL = computed(() => store.state.isRTL);
let bandera = ref(false);
const getRoute = () => {
  const route = useRoute();
  const routeArr = route.path.split("/");
  return routeArr[1];
};

onBeforeMount(() => {
  let role = localStorage.getItem("role");
  bandera.value = (role == "SuperAdmin") ? true: false;
  /*console.log("ROL: "+role);
  console.log("BANDERA: "+bandera.value);*/
});

</script>
<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-item
          to="/dashboard"
          :class="getRoute() === 'dashboard' ? 'active' : ''"
          navText="Dashboard"
        >
          <template v-slot:icon>
            <i class="ni ni-tv-2 text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/reportes"
          :class="getRoute() === 'reportes' ? 'active' : ''"
          navText="Reportes"
        >
          <template v-slot:icon>
            <i
              class="ni ni-chart-pie-35 text-danger text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/solicitudes"
          :class="getRoute() === 'solicitudes' ? 'active' : ''"
          navText="Solicitudes"
        >
          <template v-slot:icon>
            <i
              class="ni ni-calendar-grid-58 text-warning text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/administrar/usuarios"
          :class="getRoute() === '/administrar/usuarios' ? 'active' : ''"
          navText="Usuarios"
        >
          <template v-slot:icon>
            <i class="ni ni-circle-08 text-success text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item" v-show="bandera">
        <sidenav-item
          to="/administrar/administradores"
          :class="getRoute() === 'virtual-reality' ? 'active' : ''"
          navText="Administradores"
        >
          <template v-slot:icon>
            <i class="ni ni-app text-info text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/administrar/cursos"
          :class="getRoute() === 'rtl-page' ? 'active' : ''"
          navText="Cursos"
        >
          <template v-slot:icon>
            <i class="ni ni-archive-2 text-danger text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/administrar/libros"
          :class="getRoute() === 'rtl-page' ? 'active' : ''"
          navText="Libros"
        >
          <template v-slot:icon>
            <i class="ni ni-books text-info text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/administrar/noticias"
          :class="getRoute() === 'rtl-page' ? 'active' : ''"
          navText="Noticias"
        >
          <template v-slot:icon>
            <i class="ni ni-email-83 text-success text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <!--<li class="mt-3 nav-item">
        <h6
          v-if="isRTL"
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="isRTL ? 'me-4' : 'ms-2'"
        >
          صفحات المرافق
        </h6>

        <h6
          v-else
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="isRTL ? 'me-4' : 'ms-2'"
        >
          ACCOUNT PAGES
        </h6>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/profile"
          :class="getRoute() === 'profile' ? 'active' : ''"
          :navText="isRTL ? 'حساب تعريفي' : 'Profile'"
        >
          <template v-slot:icon>
            <i class="ni ni-single-02 text-dark text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/signin"
          :class="getRoute() === 'signin' ? 'active' : ''"
          :navText="isRTL ? 'تسجيل الدخول' : 'Sign In'"
        >
          <template v-slot:icon>
            <i class="ni ni-single-copy-04 text-danger text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/signup"
          :class="getRoute() === 'signup' ? 'active' : ''"
          :navText="isRTL ? 'اشتراك' : 'Sign Up'"
        >
          <template v-slot:icon>
            <i class="ni ni-collection text-info text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>-->
    </ul>
  </div>

  <!--<div class="pt-3 mx-3 mt-3 sidenav-footer">
    <sidenav-card
      :card="{
        title: 'Need Help?',
        description: 'Please check our docs',
        links: [
          {
            label: 'Documentation',
            route:
              'https://www.creative-tim.com/learning-lab/vue/overview/argon-dashboard/',
            color: 'dark',
          },
          {
            label: 'Buy now',
            route:
              'https://www.creative-tim.com/product/vue-argon-dashboard-pro?ref=vadp',
            color: 'success',
          },
        ],
      }"
    />
  </div>-->
</template>
