<template>
  <div v-show="props.rep" class="col-lg-6 mb-lg mb-4">
    <div class="card">
      <div class="p-3 pb-0 card-header">
        <h6 class="mb-0">{{ props.titulo }}</h6>
      </div>
      <div class="p-3 card-body">
        <v-card flat>
          <v-data-table
            class="mi-tabla"
            :headers="props.headers"
            :items="props.datos"
            item-key="id"
            items-per-page="5"
          >
            <template v-slot:no-data>
              <v-alert :value="true" color="warning" type="warning">
                Lo siento, no hay información disponible . . .
              </v-alert>
            </template>

            <template #[`item.cantidad`]="{ item }">
              <p>{{ formatterMoney(item.cantidad) }}</p>
            </template>

            <template #[`item.saldoActual`]="{ item }">
              <p>{{ formatterMoney(item.saldoActual) }}</p>
            </template>

            <template #[`item.solicitado`]="{ item }">
              <p>{{ formatterMoney(item.solicitado) }}</p>
            </template>

            <template #[`item.pagado`]="{ item }">
              <v-chip
                :color="item.pagado ? 'green' : 'red'"
                :text="item.pagado ? 'PAGADO!' : 'NO PAGADO!'"
                class="text-uppercase"
                size="small"
                label
              ></v-chip>
            </template>

            <template #[`item.fecha_registro`]="{ item }">
              <p>{{ formatDate(item.fecha_registro) }}</p>
            </template>

            <template #[`item.aperturaFecha`]="{ item }">
              <p>{{ formatDate(item.aperturaFecha) }}</p>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </div>
  </div>

  <div v-show="props.dash">
    <v-card :title="props.titulo" flat>
      <v-data-table
        class="mi-tabla"
        :headers="props.headers"
        :items="props.datos"
        item-key="id"
        items-per-page="5"
      >
        <template v-slot:no-data>
          <v-alert :value="true" color="warning" type="warning">
            Lo siento, no hay información disponible . . .
          </v-alert>
        </template>

        <template #[`item.cantidad`]="{ item }">
          <p>{{ formatterMoney(item.cantidad) }}</p>
        </template>

        <template #[`item.saldoActual`]="{ item }">
          <p>{{ formatterMoney(item.saldoActual) }}</p>
        </template>

        <template #[`item.solicitado`]="{ item }">
          <p>{{ formatterMoney(item.solicitado) }}</p>
        </template>

        <template #[`item.pagado`]="{ item }">
          <v-chip
            :color="item.pagado ? 'green' : 'red'"
            :text="item.pagado ? 'PAGADO!' : 'NO PAGADO!'"
            class="text-uppercase"
            size="small"
            label
          ></v-chip>
        </template>

        <template #[`item.fecha_registro`]="{ item }">
          <p>{{ formatDate(item.fecha_registro) }}</p>
        </template>

        <template #[`item.aperturaFecha`]="{ item }">
          <p>{{ formatDate(item.aperturaFecha) }}</p>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script setup>
const props = defineProps(["titulo", "headers", "datos","dash","rep"]);

const formatDate = (fecha_registro) => {
  var opciones = { year: "numeric", month: "short", day: "numeric" };
  var fecha = new Date(fecha_registro)
    .toLocaleDateString("es", opciones)
    .replace(/ /g, "-")
    .replace(".", "")
    .replace(/-([a-z])/, function (x) {
      return "-" + x[1].toUpperCase();
    });

  return fecha;
};

const formatterMoney = (value) => {
  const dollar = currencyFormatter({
    currency: "USD",
    value,
  });

  return dollar;
};

function currencyFormatter({ currency, value }) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    minimumFractionDigits: 2,
    currency,
  });
  return formatter.format(value);
}
</script>

<style scoped></style>
